import http from "../utils/http"
import Cookies from 'js-cookie'

/**
 * 店铺列表
 * @param {object} params
 */
export function shopList (params) {
    // 以下代码对于专馆并没有什么用，对城市分站有用
    let site_area_id = Cookies.get('site_area_id');
    if (site_area_id) {
        params = {
            ...params,
            site_area_id: site_area_id,
        }
    }
    return http({
        url: "/api/shop/page",
        data: params
    })
}

/**
 * 是否关注店铺
 * @param {object} params
 */
export function shopIsSubscribe (params) {
    return http({
        url: "/api/shopmember/issubscribe",
        data: params,
        forceLogin: true
    })
}

/**
 * 关注店铺
 * @param {object} params
 */
export function addShopSubscribe (params) {
    return http({
        url: "/api/shopmember/add",
        data: params,
        forceLogin: true
    })
}

/**
 * 取消关注店铺
 * @param {object} params
 */
export function deleteShopSubscribe (params) {
    return http({
        url: "/api/shopmember/delete",
        data: params,
        forceLogin: true
    })
}
